<template>
  <div class="consideration">
    <div class="radio_top">
      <span class="title_wb">流程类型：</span>
      <el-radio v-model="radio" label="1">会审</el-radio>
      <el-radio v-model="radio" label="2">普通</el-radio>
    </div>
    <div class="jointHearing" v-if="radio === '1'">
      <div class="jointHear">会审人：</div>
      <div class="sele_xz">
            <el-select v-model="pitchon" multiple placeholder="请选择会审人">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>

        <!-- <el-cascader
          v-model="pitchon"
          placeholder="请选择会审人"
          :options="options"
          :props="{ multiple: true }"
          @change="getExamine"
          filterable>
        </el-cascader> -->
      </div>
    </div>
    <div class="jointHearing" v-if="radio === '2'">
      <div class="jointHear">审核人：</div>
      <div class="sele_xz">
           <el-select v-model="pitchon" multiple placeholder="请选择会审人">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        <!-- <el-cascader
          v-model="pitchon"
          placeholder="请选择审核人"
          :options="options"
          :props="{ multiple: true }"
          @change="getExamine"
          filterable>
        </el-cascader> -->
      </div>
    </div>
    <div class="button_qq">
      <el-button class="qxiao" @click="colse">取消</el-button>
      <el-button type="primary" @click="submit">确认</el-button>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, watchEffect } from 'vue'
import { ElMessage } from 'element-plus'
import { getAuthList, approvalSave, getInfoUserId } from '@/api/user'
import { useStore } from '@/vuex/store'
export default {
  name: 'drawerOne',
  props: {
    data: {
      userName: String,
      userid: String,
      departmentId: String,
      departmentName: String
    },
    handleClose: Function
  },
  setup (props) {
    const radio = ref('2')
    const options = ref([])
    const optionsList = ref('')
    const departmentId = ref('')
    const departmentName = ref('')
    const pitchon = ref([])
    // 获取部门列表
    const getDepartment = () => {
      console.log(radio.value)
      let data = ''
      if (Number(radio.value) === 1) {
        data = '2'
      } else {
        data = '1,5'
      }
      getAuthList({
        personalType: data,
        userName: '',
        departmentName: '',
        departmentId: ''
      }).then(res => {
        if (res.code === 200) {
          const list = res.data
          list.forEach(item => {
            item.label = item.userName + '——' + item.departmentName
            item.value = item.userid + '_' + item.departmentId + '_' + item.departmentName
          })
          console.log(list)
          options.value = list
        }
      })
    }
    // 选择审批人
    const getExamine = (e) => {
      console.log(e[0])
      const list = []
      if (e) {
        e.forEach(item => {
          console.log(item)
          const arr = item.split('_')
          list.push(arr[0])
          departmentId.value = arr[1]
          departmentName.value = arr[2]
        })
        const str = list.join('/')
        pitchon.value = str
      }
    }
    // 提交审批计划
    const submit = () => {
      console.log(pitchon.value)
      getExamine(pitchon.value)
      console.log(pitchon.value)
      let industry = ''
      let industryId = ''
      if (radio.value === '1') {
        industry = props.data.departmentName
        industryId = props.data.departmentId
      }
      if (radio.value === '2') {
        industry = departmentName.value
        industryId = departmentId.value
      }
      approvalSave({
        userid: props.data.userid,
        userName: props.data.userName,
        departmentName: industry,
        departmentId: industryId,
        approvalIdList: pitchon.value,
        type: radio.value
      }).then(res => {
        if (res.code === 200) {
          ElMessage.success('添加成功')
          colse()
        }
      })
    }
    // 关闭页面
    const colse = () => {
      props.handleClose()
      pitchon.value = []
    }
    // 根据id获取员工详情
    const moreDetails = () => {
      if (props.data.userid) {
        getInfoUserId({
          userId: props.data.userid
        }).then(res => {
          if (res.code === 200) {
            console.log(res)
            if (res.data) {
              radio.value = res.data.type ? String(res.data.type) : radio.value
              const approvalName = res.data.approvalName.substring(0, res.data.approvalName.length - 1)
              const list = approvalName.split(',')
              const data = []
              list.forEach(item => {
                console.log(item)
                const a = item.split(':')[1]
                data.push(item.split(':')[0] + '_' + item.split(':')[2] + '_' + a.split('-')[1]
                )
              })
              console.log(data)
              pitchon.value = data
            }
          }
        })
      }
    }
    onMounted(() => {
      getDepartment()
      moreDetails()
      console.log(props)
    })
    watchEffect(() => {
      if (radio.value) {
        getDepartment()
      }
    })
    watchEffect(() => {
      if (props.data.userid) {
        moreDetails()
      }
    })
    return {
      radio,
      getDepartment,
      options,
      getExamine,
      optionsList,
      submit,
      departmentName,
      departmentId,
      colse,
      moreDetails,
      pitchon
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="less">
.consideration{
  .radio_top{
    width: 100%;
    height: 65px;
    line-height: 65px;
    border-top: 1px solid #d3d8dd;
    .title_wb{
      margin-left: 25px;
      margin-right: 10px;
      opacity: 1;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      color: rgba(0,0,0,0.65);
      line-height: 22px;
    }
  }
  .el-radio__label{
    font-size: 16px !important;
  }
//  .el-input--small{
//    //height: 50px !important;
//  }
//  .el-cascader--small{
//    width: 100%;
//  }
//  .el-input__suffix{
//    line-height: 48px;
//  }
//.el-input__inner{
//    height: 50px !important;
//    line-height: 43px;
//    font-size: 18px;
//  }
  .el-cascader__search-input{
    font-size: 16px;
  }
  .el-cascader-panel .el-cascader-menu__list{
    width:260px;
    text-align: center;
  }
  .el-cascader__dropdown.el-popper[role=tooltip]{
    width: 14% !important;
  }
  .jointHearing{
    width: 100%;
    margin-top: 30px;
    display: flex;
    .jointHear{
      height: 40px;
      line-height: 32px;
      margin-left: 25px;
      font-size: 16px;
      color: rgba(0,0,0,0.65);
    }
    .sele_xz{
      width: 60%;
    }
  }
  .button_qq{
    width: 100%;
    display: flex;
    margin-top: 400px;
    justify-content: center;
    .qxiao{
      margin-right: 30px;
    }
  }
}
</style>
