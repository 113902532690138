//审批列表
<template>
  <div class="monthly">
    <div class="headline">员工管理</div>
    <div class="search">
      <div class="select_sh">
        <el-select v-model="departmentId" placeholder="请选择部门">
          <el-option
            v-for="item in optionsList"
            :key="item.id"
            :label="item.departmentName"
            :value="item.departmentId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="input_sh">
        <el-input placeholder="请输入姓名" v-model="name" clearable> </el-input>
      </div>
      <!-- <div class="input3">
        <el-select v-model="working" placeholder="请选择月计划提交状态">
          <el-option
            v-for="item in titleList"
            :key="item.id"
            :label="item.text"
            :value="item.id">
          </el-option>
        </el-select>
      </div> -->
      <div class="button_cx">
        <el-button type="primary" @click="inquire">查询</el-button>
      </div>
      <div class="button_cz">
        <el-button type="primary" @click="reset" plain>重置</el-button>
      </div>
       <el-button
              type="primary"
              @click="popupWindows"
              :disabled="disable"
              style="width: 110px; margin-left: 100px"
              >月计划生成</el-button
            >
      <!-- <div class="button_qdc">
        <el-popover placement="top" :width="260" v-model:visible="visible">
          <div class="block" style="margin: 10px 0">
            <el-select
              v-model="value"
              filterable
              @change="optionsOnChange"
              placeholder="请选择员工姓名"
            >
              <el-option
                v-for="item in optionsList2"
                :key="item.id"
                :label="item.userName"
                :value="item.userid + ',' + item.userName"
              >
              </el-option>
            </el-select>
            <el-date-picker
              v-model="selectionTime"
              type="month"
              @change="timing2"
              placeholder="选择生成日期"
            >
            </el-date-picker>
          </div>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="cancel">取消</el-button>
            <el-button type="primary" size="mini" @click="handleSave"
              >确定</el-button
            >
          </div>
          <template #reference>

          </template>
        </el-popover>
      </div> -->
    </div>
    <el-dialog v-model="visible" title="Shipping address">
          <el-select
              v-model="value"
              filterable
              @change="optionsOnChange"
              placeholder="请选择员工姓名"
            >
              <el-option
                v-for="item in optionsList2"
                :key="item.id"
                :label="item.userName"
                :value="item.userid + ',' + item.userName"
              >
              </el-option>
            </el-select>
            <el-date-picker
              v-model="selectionTime"
              type="month"
              @change="timing2"
              style="margin-left:40px;"
              placeholder="选择生成日期"
            >
            </el-date-picker>
           <template #footer>
          <span class="dialog-footer">
            <el-button style="width: 80px;" @click="visible = false">取消</el-button>
            <el-button style="width: 80px; margin-left: 30px" type="primary" @click="handleSave"
              >确定</el-button
            >
          </span>
      </template>
      </el-dialog>
    <div class="tabe_class">
      <el-table
        :data="tableData"
        style="width: 91.75%"
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column prop="id" label="序号" width="100"> </el-table-column>
        <el-table-column prop="userName" label="姓名" width="200">
        </el-table-column>
        <el-table-column prop="departmentName" label="部门" width="300">
        </el-table-column>
        <el-table-column prop="position" label="岗位" width="300">
        </el-table-column>
        <!-- <el-table-column
          prop="month"
          label="日期"
          sortable
          width="150"
        >
        </el-table-column> -->
        <el-table-column prop="entryTime" label="入职时间" sortable width="350">
        </el-table-column>

        <el-table-column label="操作" width="350">
          <template #default="data">
            <div class="inline">
              <el-button
                v-if="data.row.planState <= 1"
                type="text"
                @click="
                  () => {
                    handleEdit(data);
                  }
                "
                >提醒</el-button
              >
            </div>
            <div class="inline">
              <el-button
                type="text"
                @click="
                  () => {
                    handleSet(data);
                  }
                "
                >设置</el-button
              >
            </div>
            <div class="inline">
              <el-switch
                v-model="data.row.workState"
                @change="
                  (e) => {
                    whetherOffice(e, data.row.userid);
                  }
                "
                :active-value="1"
                :inactive-value="2"
              >
              </el-switch>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationY">
      <el-pagination
        :page-size="10"
        :pager-count="7"
        background
        layout="prev, pager, next"
        @current-change="currentChange"
        :total="total"
      >
      </el-pagination>
    </div>
    <div>
      <el-drawer
        title="设置"
        v-model="drawer"
        :before-close="handleClose"
        destroy-on-close
      >
        <drawerOne :data="data" :handleClose="handleClose" />
      </el-drawer>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import {
  staffList,
  department,
  sendNotice,
  deleteUser,
  userGetList,
  monthPlanSave
} from '@/api/user'
import { ElLoading, ElMessage } from 'element-plus'
import { useStore } from '@/vuex/store'
import drawerOne from '../drawer/drawerOne'
export default {
  name: 'monthlyPerformance',
  setup () {
    const tableData = ref([])
    const departmentId = ref('')
    const name = ref('')
    const drawer = ref(false)
    const total = ref('')
    const page = ref(1)
    const selectionTime = ref('')
    const visible = ref(false)
    const loading = ref(false)
    const userList = ref('')
    const value = ref('')
    const disable = ref(false)
    const data = ref({
      userName: '',
      userid: '',
      departmentId: '',
      departmentName: ''
    })
    const titles = ref([
      '暂无状态',
      '未提交',
      '已提交',
      '拒绝',
      '同意',
      '待自评',
      '待复评',
      '完成'
    ])
    const titleList = ref([
      { text: '未提交', id: 1 },
      { text: '已提交', id: 2 },
      { text: '拒绝', id: 3 },
      { text: '同意', id: 4 },
      { text: '待自评', id: 5 },
      { text: '待复评', id: 6 },
      { text: '完成', id: 7 }
    ])
    const optionsList = ref([])
    const optionsList2 = ref([])
    // 获取页面数据
    const getStaffList = () => {
      console.log(page.value)
      staffList({
        current: page.value,
        pageSize: 10,
        userName: name.value,
        departmentId: departmentId.value
      }).then((res) => {
        console.log(res.data)
        if (res.code === 200) {
          total.value = res.data.total
          tableData.value = res.data.records
        }
      })
    }
    // 获取部门列表
    const getDepartment = () => {
      department({}).then((res) => {
        if (res.code === 200) {
          optionsList.value = res.data
        }
      })
    }
    // 在职离职调整
    const whetherOffice = (e, userId) => {
      if (typeof userId !== 'undefined') {
        deleteUser({
          useid: userId,
          workState: e
        }).then((res) => {
          if (res.code === 200) {
            ElMessage.success('修改成功')
            getStaffList()
          }
        })
      }
    }
    // 查询
    const inquire = () => {
      getStaffList()
    }
    // 重置
    const reset = () => {
      page.value = 1
      name.value = ''
      departmentId.value = ''
      getStaffList()
    }
    // 下一页
    const currentChange = (e) => {
      if (e) {
        page.value = e
        getStaffList()
      }
    }
    // 关闭页面
    const handleClose = () => {
      getStaffList()
      drawer.value = false
    }
    const handleEdit = (e) => {
      if (e && e.row.month && e.row.userid) {
        sendNotice({
          month: e.row.month,
          userid: e.row.userid
        }).then((res) => {
          if (res.code === 200) {
            console.log(11111111)
            ElMessage.success('提醒成功')
          } else {
            ElMessage.error('提醒失败')
          }
        })
      }
    }
    const handleSet = (e) => {
      drawer.value = true
      data.value.userName = e.row.userName
      data.value.departmentId = e.row.departmentId
      data.value.userid = e.row.userid
      data.value.departmentName = e.row.departmentName
      console.log(e)
    }
    // 导出弹窗
    const popupWindows = () => {
      visible.value = true
    }
    // 搜索用户
    const remoteMethod = () => {
      userGetList({}).then((res) => {
        if (res.code === 200) {
          console.log(res.data)
          optionsList2.value = res.data
        }
      })
    }
    // 选中员工
    const optionsOnChange = (e) => {
      console.log(e)
      value.value = e
    }
    // 获取时间
    const timing2 = (e) => {
      if (e) {
        const dateData = e
        const date = new Date(dateData)
        const y = date.getFullYear()
        let m = date.getMonth() + 1
        m = m < 10 ? '0' + m : m
        const time = y + '-' + m
        selectionTime.value = time
      }
    }
    // 关闭弹窗
    const cancel = () => {
      visible.value = false
      selectionTime.value = ''
    }
    // 生成月计划
    const handleSave = () => {
      const data = value.value.split(',')
      monthPlanSave({
        userId: data[0],
        month: selectionTime.value,
        userName: data[1]
      }).then((res) => {
        if (res.code === 200) {
          ElMessage.success('生成成功')
          visible.value = false
        } else {
          ElMessage.error('生成失败')
        }
      })
    }
    onMounted(() => {
      getStaffList()
      getDepartment()
      remoteMethod()
    })
    return {
      optionsList,
      departmentId,
      getStaffList,
      name,
      tableData,
      handleEdit,
      handleSet,
      drawer,
      total,
      currentChange,
      page,
      titles,
      inquire,
      reset,
      getDepartment,
      titleList,
      data,
      handleClose,
      handleSave,
      whetherOffice,
      timing2,
      selectionTime,
      cancel,
      visible,
      remoteMethod,
      loading,
      optionsList2,
      optionsOnChange,
      userList,
      value,
      popupWindows,
      disable
    }
  },
  components: {
    drawerOne
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.monthly {
  .headline {
    width: 100%;
    height: 80px;
    line-height: 80px;
    padding-left: 200px;
  }
  .search {
    display: flex;
    .select_sh {
      width: 224px;
      margin-left: 40px;
    }
    .input_sh {
      width: 224px;
      margin-left: 12px;
    }
    .input3 {
      width: 224px;
      margin-left: 23px;
    }
    .button_cx {
      margin-left: 30px;
    }
    .button_cz {
      margin-left: 10px;
    }
  }
  .monthly .el-button--small {
    padding: 4px 4px !important;
  }
  .el-input--small .el-input__inner {
    width: 100%;
    height: 40px;
    line-height: 32px;
  }
  .el-switch.is-disabled {
    opacity: 0.6;
    margin-left: 17px;
  }
  .el-drawer__open .el-drawer.rtl {
    width: 32% !important;
  }
  .el-button {
    width: 60px;
    font-size: 14px !important;
    border-right: 1px solid #d3d8dd;
  }
  .el-drawer__header {
    margin-bottom: 16px !important;
  }
  .el-button + .el-button,
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0px;
    margin-right: 15px;
    font-size: 14px;
  }
  .el-table th {
    background-color: #e6ebf0;
    color: #000;
  }
  .el-pagination {
    margin-right: 62px;
    margin-top: 50px;
  }
  .el-switch {
    margin-left: 20px;
  }
  .el-table th,
  .el-table tr {
    font-size: 16px;
  }
  .tabe_class {
    margin-left: 195px;
    margin-top: 20px;
  }
  .dian {
    margin-right: 8px;
    content: " ";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-top: 9px;
  }
  .green {
    background-color: #52c41a;
  }
  .red {
    background-color: red;
  }
  .inline {
    width: 50px;
    display: inline-block;
  }
  .inlines {
    width: 80px;
    display: inline-block;
  }
  .paginationY {
    width: 100%;
    text-align: center;
    margin-right: 50px;
  }
}
</style>
